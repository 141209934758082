import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { ModalPage } from './modal.page';


@NgModule({
  declarations: [ModalPage],
  imports: [
      BrowserModule,
    IonicModule.forRoot(),
    IonicModule
  ],
  bootstrap: [ModalPage],
  entryComponents: [ModalPage]
})
export class ModalModule {}
