import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { ModalModule } from './modal/modal.module';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import {  HashLocationStrategy, LocationStrategy, PathLocationStrategy, APP_BASE_HREF, KeyValue } from '@angular/common';
import { KeyvaluePipe } from './keyvalue.pipe';

//import { HomePage } from './busquedaPages/listaPages/home.page';
@NgModule({
  declarations: [AppComponent, KeyvaluePipe],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    IonicModule,
    ModalModule
    

  ],
  providers: [
    StatusBar,
    SplashScreen,
        YoutubeVideoPlayer,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
  
})
export class AppModule {}
