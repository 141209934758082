import { Component, ViewChild } from '@angular/core';
import { Platform, IonContent } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';
import { AlertController, ModalController} from '@ionic/angular';
  import {sha512} from "js-sha512";
import { ModalPage } from './modal/modal.page';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
  	'app.component.scss'
  ]

})
export class AppComponent {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  name=localStorage.getItem('usuario');
  TITULO=null;
  ICONO=null;
  SECTIONS=new Array();
  newTrustFormVisible: true;
  private url=localStorage.getItem('WebService');
  private estilo=localStorage.getItem('Estilo2')+'/CINVESTAV/assets/css/colores2.php';
  private urlEstilo=localStorage.getItem('Estilo2');
  private urlImages=localStorage.getItem('Estilo2')+'/CINVESTAV/uploads/galeria/';
  public trustedUrl;
  public trustedUrl2;
  public showSubmenu;
  public gallery;
  usuario=this.name;
  ROLE="-100";
  public appPages;
  public research;
  public line;
  public select;
  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private AC: AlertController,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    public modalController: ModalController
  ) {
    
        this.select={
          linea:null,
          holder:null,
          design:null,
          background:null
        };
        this.research = {
          colors:{id_research:-1,primero:"#000000",segundo:"#ffffff",cajas:"#bbbbbb",text:"#222222"},
          title:"", 
          holder:"",
          url:"",
          design:"",
          description:"",
          background:"",
          linea:"",
          page:""};
        this.line = {
          title:"", 
          holder:"",
          description:"",
          page:""}; 
          //this.trustedUrl =sanitizer.bypassSecurityTrustResourceUrl(this.estilo);
          if(localStorage.getItem('usuario')!=null){
            this.ROLE=localStorage.getItem('role');
            this.usuario=localStorage.getItem('usuario');
            this.gallery=new Array();
            let data2 = {
                name: '',
                op: 1,
                research:"*"
            };
            new Promise<void>((resolver, rechazar) => {
            this.http.post(this.url+'/imagesAll', data2).pipe().subscribe(response2 => {
              for(let index=0;index<response2["message"].length;index++){
                this.gallery[response2["message"][index]["id_galleries"]]=this.urlEstilo+"/CINVESTAV/uploads/galeria/"+response2["message"][index]["galleries_image"];
              }resolver();
              });
            })
            .then(() => {
               if(-100!=parseInt(localStorage.getItem('role'))){
                  this.menus();
              this.initializeApp();}
            })
            .catch(() => {
               
            });
          }
    
  }
   ScrollToBottom() {
    this.content.scrollToBottom(1500);
  }
  closeForm(id){
    var div = document.getElementById(id);
    div.classList.toggle("hidden");
  }
  ScrollToTop() {
    this.content.scrollToTop(1500);
  }

  ScrollToPoint(X, Y) {
    this.content.scrollToPoint(X, Y, 1500);
  }
  scrollToLabel(label)
    {
      var id = document.getElementById(label);
      this.content.scrollToPoint(0,id.offsetTop-60,500)
    }
cambio($event){
this.research[$event.target.name]=$event.target.value;
}
  newResearch() {
   	for(let value in this.research.colors){
      if(value!="id_research")
        this.research.colors[value]=this.research.colors[value]+"ff";
    }
    let datos={
   		research:{title:this.research.title,
      holder:this.research.holder,
      design:"<principal>"+this.research.design+"</principal>",
   		description:this.research.description,
   		id_line_research:this.research.linea,
   		background:this.research.background},
      colors:this.research.colors
   	};
    
    let data = {
          name: this.name,
          todo: datos,
          op: 4
      };
    this.http.post(this.url+'/research', data).pipe(
      ).subscribe(response => {
      });
  } 
   newLine() {
    let datos={
      title:this.line.title,
      holder:this.line.holder,
      description:this.line.description
    };
    let data = {
          name: this.name,
          todo: datos,
          op: 4
      };
    this.http.post(this.url+'/line', data).pipe(
      ).subscribe(response => {
      });
  } 
  newFont() {
    var id = document.getElementById("nFont");
    const collection3 = id.children;
    let datos={};
    for (let i = 0; i < collection3.length; i++){
      for(let j=0;j<collection3[i].children.length;j++){
        let ele=collection3[i].children[j];
        if(ele["name"]!=undefined)
          datos[ele["name"]]=ele["value"];
      }
    }
    let flag=true;
    for(let ele in datos){
      let dat=datos[ele];
      while(dat.indexOf(" ")!=-1)
        dat=dat.replace(" ","");
      if(dat=="")
        flag=false;
    }
    if(flag){
      let data = {
            name: this.name,
            todo: datos,
            op: 1
        };
      this.http.post(this.url+'/font', data).pipe(
        ).subscribe(response => {
        });
    }
  } 

  menuItemHandler(event){
    if((""+event).indexOf(":")==-1){
      let keys=Object.keys(this.showSubmenu);
      for(let i=0;i<keys.length;i++){
        if(keys[i]!=event) 
          this.showSubmenu[keys[i]]=false;
      }
    }
    this.showSubmenu[event]=!this.showSubmenu[event];
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      //this.splashScreen.hide();
    });
    
  }
    menus() {
      let data = {
          consulta: ['pages'],
          elementos: '*',
          usr:"this.usuario",
          opcion:1
      };
      this.http.post(this.url+'/pages', data).pipe(
      ).subscribe(response => {
        var page=-1;
        var id_page=new Array();
        var line=-1;
        var id_line=new Array();
        var SSM=new Array();
        for(let index=0;index<response["message"].length;index++) {
          let res=response["message"][index];
          if(page!=res.pages_id_page){
            id_page[id_page.length]=res;
            id_page[id_page.length-1]["subPage"]=new Array();
            page=res.pages_id_page;
            line=-1;
          }
          if(res["subPages"]["line_research_id_line_research"]!=null){
            if(line!=res["subPages"].line_research_id_line_research || page!=res.pages_id_page){
              SSM[page]=false;
              id_page[id_page.length-1]["subPage"].push(res["subPages"]); 
              id_page[id_page.length-1]["subPage"][id_page[id_page.length-1]["subPage"].length-1]["research"]=new Array();
              line=res["subPages"].line_research_id_line_research;
            }
            if(res["researches"]["research_id_research"]!=null && id_page[id_page.length-1]["subPage"][id_page[id_page.length-1]["subPage"].length-1]!=null){
              SSM[page+":"+line]=false;
              res["researches"]["research_icono"]=this.getImages(res["researches"]["research_icono"]);
              this.ICONO=this.getImages(res["researches"]["research_icono"]);
              id_page[id_page.length-1]["subPage"][id_page[id_page.length-1]["subPage"].length-1]["research"].push(res["researches"]);  
            }
            else
                id_page[id_page.length-1]["subPage"][id_page[id_page.length-1]["subPage"].length-1]=null;
             
          }
          else{
            id_page[id_page.length-1]["subPage"]=null;
          id_page[id_page.length-1]["subPages"]=null;
          id_page[id_page.length-1]["researches"]=null;
          }
          
        }
        this.showSubmenu=SSM;
        this.appPages=id_page;
      });
      let data2 = {
          consulta: ['line_research'],
          elementos: '*',
          usr:"this.usuario",
          opcion:2
      };
      this.http.post(this.url+'/pages', data2).pipe(
      ).subscribe(response => {
        var linea=new Array();
        response["message"].forEach(function(res) {
            linea[linea.length]=res;
        });
        this.select.linea=linea;   
      });
      let data3 = {
          consulta: ['galleries'],
          elementos: '*',
          usr:"this.usuario",
          opcion:2
      };
      this.http.post(this.url+'/pages', data3).pipe(
      ).subscribe(response => {
        var gall=new Array();
        for(let i=0;i<response["message"].length;i++){
            response["message"][i]["image"]=this.urlImages+response["message"][i]["image"];
            gall[i]=response["message"][i];
        }
        this.select.background=gall;   
      });
      let data4 = {
          consulta: ['users'],
          elementos: ["id_user",
                      "name",
                      "lastName"],
          usr:"this.usuario",
          opcion:2
      };
      this.http.post(this.url+'/pages', data4).pipe(
      ).subscribe(response => {
        var holder=new Array();
        response["message"].forEach(function(res) {
            holder[holder.length]=res;
        });
        this.select.holder=holder;   
      });
      let data5 = {
          consulta: ['designs'],
          elementos: ["id_design",
                      "nombre"],
          usr:"this.usuario",
          opcion:2
      };
      this.http.post(this.url+'/pages', data5).pipe(
      ).subscribe(response => {
        var holder=new Array();
        response["message"].forEach(function(res) {
            holder[holder.length]=res;
        });
        this.select.design=holder;   
      });
    }
    sesion(){
    this.logFormulario();
  }
     async logFormulario() {
    var mensaje=new Array();
    const alert = await this.AC.create({
      header:"CINVESTAV",
      message: mensaje.join(""),
      buttons: [
        {
          text: 'Sign Out',
          role: 'delete',
          cssClass: 'secondary',
          handler: (blah) => {
            this.logOut();
          }
        },{
          text: 'Log in',
          role: 'update',
          cssClass: 'primary',
          handler: (blah) => {
            this.logIn();
          }
        }, {
          text: 'Ok',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
  logOut(){
    localStorage.removeItem('usuario');
    this.router.navigateByUrl('/');

  }
  async logIn(){
    let form=new Array();
    let col=['Usuario','password'];
    let newD={};//title:dat.title, text:dat.text};
    col.forEach(function(res) {
      newD[res]="";
      let aux={};
      aux["type"]="text";
      aux["name"]=res;
      aux["value"]='';
      form.push(aux);
    });
    const alert = await this.AC.create({
      header: "Sign in",
      inputs: form,
      buttons: [
         {
            text: 'Ok',
            handler: (b) => {
              this.logInCheck(b);
            }
        }
      ]
    });
    await alert.present();
  }
  logInCheck(e){
    
    e["password"]=sha512(e["password"]);
    let data = {
          data: e,
          op:1
      };
    this.http.post(this.url+'/login', data).pipe(
      ).subscribe(response => {
          localStorage.setItem('usuario', e.usuario);
          this.router.navigateByUrl('/');
      });
  } 

  link(){
    this.linkFormulario();
  }
  async linkFormulario(){
    let form=new Array();
    let col=['WebService','Estilo','Estilo2'];
    let newD={};//title:dat.title, text:dat.text};
    let message="";
    col.forEach(function(res) {
      newD[res]="";
      let aux={};
      aux["type"]="text";
      aux["name"]=res;
      aux["label"]=res;
      aux["value"]=localStorage.getItem(res);
      form.push(aux);
      message+=res+":"+localStorage.getItem(res)+"<br>";
    });
    const alert = await this.AC.create({
      header: "Enlaces",
      message:message,
      inputs: form,
      buttons: [
         {
            text: 'Ok',
            handler: (b) => {
              this.setLink(b,col);
            }
        }
      ]
    });
    await alert.present();
  }
  setLink(e,col){
    col.forEach(function(res){
      //console.log(e[res]);
      localStorage.setItem(res,e[res] );
    });
    window.location.reload();
  } 
  presentModal(){
    this.showBackground();
  }  
  async showBackground() {
    let select0=this.research.background.replace("<slide>","").replace("</>","").split(",");
    for (let i = 0; i < this.select.background.length; i++){
      if(select0.indexOf(""+this.select.background[i]["id_galleries"])!=-1)
        this.select.background[i]["class"]="select";
      else
        this.select.background[i]["class"]="";
    }
    const modal = await this.modalController.create({
      component: ModalPage,
      componentProps: {
        'data': this.select.background,
        'tipo':1
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data!=undefined)  
  	 this.research.background="<slide>"+data.dismissed.join(",")+"</>";
  }
  getImages(datos){
  let regreso=new Array();
  if(Array.isArray(datos))
    for(let index of datos){
      regreso.push(this.gallery[parseInt(index)]);
    }
  else
    regreso.push(this.gallery[datos]);
  return regreso;
}
  setTitle(titulo, id){
    let data = {
          name: "criss",
          section: id,
          op: 1
      };
    let sections=new Array();
    this.http.post(this.url+'/section', data).pipe(
      ).subscribe(response => {
          this.SECTIONS=response["message"];
      });
    this.TITULO=titulo;
  }
  scrollTo(label)
    {
      var id = document.getElementById("seccion"+label);
      this.content.scrollToPoint(0,id.offsetTop-60,500)
    }
}
