import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if(localStorage.getItem('WebService')==null){
	localStorage.setItem('WebService',environment.WebService);
	localStorage.setItem('Estilo',environment.Estilo);
	localStorage.setItem('Images',environment.Images);
}
if(localStorage.getItem('Images')==null){
	localStorage.setItem('Images',environment.Images);
}
localStorage.setItem('Estilo2',environment.Estilo2);
if (environment.production) {
  enableProdMode();
}
	platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.log(err));
