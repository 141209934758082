import { Component, Input } from '@angular/core';
import { NavParams, Platform ,AlertController, ModalController, IonRouterOutlet, ActionSheetController } from '@ionic/angular';


@Component({
  selector: 'modal-page',
    templateUrl: 'page.html'
})
export class ModalPage {
  pet;
  @Input() data: any[];

  constructor(navParams: NavParams,
        private platform: Platform,
            public modalController: ModalController,
                private AC: AlertController
) {
    // componentProps can also be accessed at construction time using NavParams
    this.pet=navParams.get('data');
  }
  dismiss() {
    let val=new Array();
    const collection = document.getElementsByClassName("select");
        for (let i = 0; i < collection.length; i++) {
          val.push(collection[i].id);
        }
    this.modalController.dismiss({
      'dismissed': val
    });
  }
  select(val) {
    document.getElementById(val).classList.toggle("select");
  }

}