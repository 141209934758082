import { NgModule } from '@angular/core';
import { PreloadAllModules,NoPreloading, RouterModule, Routes } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
//import { HomePage } from './busquedaPages/listaPages/home.page';

const routes: Routes = [
  { path: '404', pathMatch: 'full', 
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
//    loadChildren: () => import('./busquedaPages/listaPages/home.module').then(m => m.HomePageModule)
    },
  {
    path: '',
    //loadChildren: () => import('./busquedaPages/listaPages/home.module').then(m => m.HomePageModule),
    //component: HomePage,
    children:[
      {
        path: ":WP",
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
      }, 
      {
        path: ":WP/sitemap",
        loadChildren: () => import('./sitemap/home.module').then(m => m.HomePageModule),
        pathMatch: 'prefix'
      },
      {
        path: '',
//        loadChildren: () => import('./busquedaPages/listaPages/home.module').then(m => m.HomePageModule),
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
        pathMatch: 'full'
      }
    ]
  },
  {
    path: "research/:WP",
    loadChildren: () => import('./research/research.module').then(m => m.pageNModule)
  },
    
  {
    path: 'design',
    loadChildren: () => import('./design/design.module').then(m => m.pageNModule)
  },
  {
    path: 'subseccion',
    loadChildren: () => import('./subseccion/subseccion.module').then(m => m.subseccionModule)
  },
  {
    path: 'panel',
    loadChildren: () => import('./panel/panel.module').then(m => m.panelModule)
  },
  {
    path: 'research',
    loadChildren: () => import('./research/research.module').then(m => m.pageNModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes,{ useHash: true })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
  constructor(
    ){
    }
}
